export default {
  error_occurred: 'Žao nam je, došlo je do pogreške. Pokušajte ponovno za koji trenutak.',
  new_content_available: "Postoji novo ažuriranje stranice, molimo kliknite 'Osvježi' za ponovno učitavanje stranice.",
  refresh_button_title: 'Osvježiti',
  force_reload_message: 'Žao nam je, došlo je do pogreške. Naš tim već radi na tome. Molimo pokušajte ponovno učitati web stranicu za trenutak.',
  sign_up: 'Stvorite račun',
  service: 'Servis',
  employee: 'Zaposlenik',
  select_employee: 'Odaberite osobu',
  employees: 'Zaposlenici',
  services: 'Usluge',
  duration: 'Trajanje',
  from: 'Iz',
  select_date: 'Odaberite datum',
  no_account_question: 'Još nemate račun?',
  name: 'Ime',
  surname: 'Prezime',
  name_and_surname: 'Puno ime',
  phone: 'Telefon',
  password: 'Lozinka',
  field_is_required: 'Ovo polje je obavezno.',
  value_is_too_long: 'Vrijednost je preduga.',
  value_is_too_short: 'Vrijednost je prekratka.',
  signup_was_successful: 'Registracija je uspjela. Prijava na panel.',
  signup_has_failed: 'Registracija nije uspjela. Pokušajte ponovno za koji trenutak.',
  email_is_taken: 'Račun s navedenom adresom e-pošte već postoji. Ako ste zaboravili lozinku, koristite opciju podsjetnika.',
  email_or_password_incorrect: 'E-mail adresa ili lozinka su netočne. Ako ste zaboravili lozinku, koristite opciju podsjetnika.',
  account_not_found: 'Račun nije pronađen.',
  page_not_found: 'Ups! Stranica koju ste tražili nije pronađena :(',
  go_back_to_main_page: 'Vratite se na početnu stranicu i pokušajte ponovo.',
  bookings: 'Rezervacije',
  account: 'Moj račun',
  date: 'Datum',
  cancel: 'Otkazati',
  date_from: 'Datum od',
  date_to: 'Datum do',
  nothing_found_here: 'Ništa nije pronađeno.',
  old_password: 'Stara lozinka',
  remove: 'Izbrisati',
  phone_is_invalid: 'Broj telefona nije točan.',
  book: 'Knjiga',
  sign_up_2: 'Stvorite račun',
  log_in: 'Prijaviti se',
  your_email_address: 'Vaša email adresa',
  select: 'Izaberi',
  filters: 'Filteri',
  expand: 'Proširiti',
  collapse: 'Kolaps',
  bookings_drawer_title: 'Odaberite filtre za prikaz samo rezervacija koje tražite.',
  clear_filters: 'Resetiraj filtere',
  show_more: 'Prikaži više',
  dont_you_remember_password: 'Zaboravili ste zaporku?',
  password_information: 'Lozinka se mora sastojati od najmanje 8 znakova, sadržavati veliko slovo, broj i poseban znak.',
  forgotten_password_button: 'Pošaljite link za promjenu lozinke',
  do_you_already_have_an_account: 'Već imate račun?',
  reset_password_title: 'Postavite novu lozinku',
  reset_password_subtitle: 'U nastavku unesite svoju novu lozinku.',
  reset_password_button: 'Spremite novu lozinku',
  profile: 'Profil',
  logout: 'Odjavi se',
  cancel_booking: 'Otkažite svoju rezervaciju',
  status_approved: 'Odobreno',
  status_payment: 'Čeka se isplata',
  status_done: 'Završeno',
  status_cancelled: 'Otkazano',
  cancel_booking_dialog_title: 'Želite li otkazati svoju rezervaciju?',
  cancel_booking_dialog_information: 'Ova se radnja ne može poništiti!',
  cancel_booking_dialog_button: 'Da, otkaži moju rezervaciju',
  my_profile: 'Moj profil',
  my_data: 'Moji podaci',
  upload_new_photo: 'Dodajte novu fotografiju',
  birthday: 'Rođendan',
  save_changes: 'Spremi promjene',
  phone_number: 'Broj telefona',
  directional: 'pozivni broj',
  save_new_password: 'Spremite novu lozinku',
  new_password: 'Nova lozinka',
  password_change: 'Promjena lozinke',
  view: 'Pogled',
  services_2: 'Usluge',
  accept_terms_and_conditions_1: 'Klikom na donju opciju slažem se s',
  accept_terms_and_conditions_2: 'Odredbe i uvjeti',
  accept_terms_and_conditions_3: 'i potvrđujem da sam pročitao',
  accept_terms_and_conditions_4: 'Izjava o privatnosti.',
  to: 'Do',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Prijaviti se',
  email_is_incorrect: 'E-mail adresa je netočna.',
  logged_out: 'Odjavljeni ste',
  booking_dialog_date_is_already_taken: 'Odabrano vrijeme nije dostupno. Biste li odabrali drugo mjesto?',
  data_search: 'Traženje podataka. Molimo pričekajte...',
  no_employees: 'Nema zaposlenih',
  no_services: 'Nema usluga',
  no_categories: 'Nema kategorija',
  no_bookings: 'Rezervacije nisu pronađene.',
  no_subscriptions: 'Nisu pronađene pretplate.',
  female: 'Žena',
  male: 'Muški',
  other: 'ostalo',
  no_numeric_in_password: 'Nema numeričkog znaka u zaporci.',
  no_lowercase_in_password: 'Bez malog slova u zaporci.',
  no_uppercase_in_password: 'Nema velikog slova u zaporci.',
  no_special_character: 'Nema posebnog karaktera.',
  min_n_characters: 'Najmanji broj znakova je @{min}.',
  max_n_characters: 'Maksimalni broj znakova je @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'Lozinka nije promijenjena. Veza je vjerojatno istekla.',
  reset_password_success_title: 'Vaša lozinka je promijenjena.',
  reset_password_success_text: 'Možete se prijaviti sa svojom novom lozinkom.',
  account_activation: 'Aktivacija računa',
  account_activation_success: 'Račun je aktiviran.',
  account_activation_fail: 'Došlo je do problema prilikom aktivacije vašeg računa. Čini se da je račun već aktivan ili veza više nije važeća.',
  account_activation_processing: 'Aktivacija vašeg računa. Molimo pričekajte...',
  account_activation_resend_title: 'Račun nije aktiviran',
  account_activation_resend_text: 'Aktivirajte svoj račun klikom na poveznicu za aktivaciju u e-poruci koju smo vam poslali.',
  account_activation_resend_button: 'Pošalji opet',
  confirm_send_activate_link_dialog_subtitle: 'Poslali smo e-poruku s vezom za aktivaciju na ranije navedenu e-mail adresu.',
  file_is_too_large: 'Odabrana datoteka je prevelika.',
  appointment_title: 'Odaberite osobu, datum i vrijeme rezervacije',
  others: 'ostalo',
  countryLabel: 'Zemlja',
  requiredMessages: 'Potreban je broj telefona',
  date_of_visit: 'Datum',
  hour_of_visit: 'Vrijeme',
  thank_you_for_booking: 'Vaša rezervacija',
  no_free_hours: 'Nema dostupnih termina za odabrani dan.',
  any_person: 'Slučajni izbor',
  password_changed: 'Vaša lozinka je promijenjena',
  incorrect_old_password: 'Stara lozinka je netočna',
  error404: 'Greška 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'U redu',
  web_page_temporary_disabled: 'Oprosti! Ova stranica je privremeno onemogućena.',
  profile_update_success: 'Vaši podaci su spremljeni.',
  form_is_invalid: 'Obrazac sadrži pogreške. Provjerite označena polja i pokušajte ponovno.',
  search: 'traži',
  pay_online_button_title: 'Platite online',
  account_not_activated_info: 'E-mail adresa nije potvrđena. Potvrdite svoju e-mail adresu klikom na link u poslanoj poruci.',
  paid: 'Plaćeno',
  payments: 'Plaćanja',
  payment_service: 'Servis',
  payment_date: 'Datum',
  payment_price: 'Cijena',
  payment_success_title: 'Uplata je uspjela, hvala!',
  payment_fail_title: 'Plaćanje nije uspjelo, pokušajte ponovo.',
  pay_again: 'Platite opet',
  newsletter_subscribe_success: 'Hvala vam! Vaša email adresa je potvrđena.',
  newsletter_subscribe_fail: 'Došlo je do problema prilikom aktivacije vaše adrese e-pošte. Čini se da je adresa e-pošte već aktivna ili poveznica više nije važeća.',
  newsletter_send_again: 'Pošalji opet',
  newsletter_send_again_success: 'Aktivacijski link je ponovno poslan.',
  send_again_fail: 'Došlo je do problema prilikom generiranja nove aktivacijske veze, možda je vaša adresa e-pošte već aktivna ili ste upravo generirali vezu.',
  newsletter_email_is_used: 'E-mail adresa je već na popisu, ako adresa nije potvrđena, možemo ponovno poslati aktivacijski link.',
  newsletter_unsubscribe_success: 'Hvala vam! Vaša adresa e-pošte je uklonjena.',
  newsletter_unsubscribe_fail: 'Došlo je do problema prilikom brisanja adrese e-pošte. Čini se da je adresa e-pošte već izbrisana ili je veza istekla.',
  booking_payments: 'Rezervacije',
  simple_store_product_transactions: 'Proizvodi',
  payment_type_select_box: 'Odaberite vrstu plaćanja',
  payment_description: 'Opis',
  add_to_cart: 'Dodaj u košaricu',
  remove_from_cart: 'Izvadite iz košarice',
  save: 'Uštedjeti',
  bookings_cart_summary: 'Sažetak rezervacije',
  today: 'Danas',
  fill_booking_details_title: 'Unesite detalje',
  create_an_account_question: 'Napravite račun i pošaljite lozinku na e-mail adresu',
  value_is_incorrect: 'Ova vrijednost nije važeća.',
  first_available_date: 'Dostupni termini uključeni',
  service_location_1: 'Adresa',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Ostalo',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Mjesto',
  booking_not_found: 'Rezervacija nije pronađena.',
  booking_canceled: 'Rezervacija je otkazana.',
  booking_canceled_fail: 'Ova se rezervacija ne može otkazati.',
  day: 'Dan',
  week: 'Tjedan',
  month: 'Mjesec',
  year: 'Godina',
  booking_plural_1: 'Rezervacija',
  booking_plural_2: 'Rezervacije',
  booking_plural_3: 'Rezervacije',
  booking_plural_4: 'Rezervacije',
  booking_plural_5: 'Rezervacije',
  day_plural_1: 'Dan',
  day_plural_2: 'dana',
  day_plural_3: 'dana',
  day_plural_4: 'dana',
  day_plural_5: 'dana',
  week_plural_1: 'Tjedan',
  week_plural_2: 'Tjedni',
  week_plural_3: 'Tjedni',
  week_plural_4: 'Tjedni',
  week_plural_5: 'Tjedni',
  month_plural_1: 'Mjesec',
  month_plural_2: 'mjeseci',
  month_plural_3: 'mjeseci',
  month_plural_4: 'mjeseci',
  month_plural_5: 'mjeseci',
  year_plural_1: 'Godina',
  year_plural_2: 'Godine',
  year_plural_3: 'Godine',
  year_plural_4: 'Godine',
  year_plural_5: 'Godine',
  tax: 'PDV ID',
  tax_prefix: 'Prefiks',
  get_company_data: 'traži',
  get_company_data_failed: 'Podaci se ne mogu preuzeti.',
  company_name: 'Naziv tvrtke',
  street_and_number: 'Ulica i broj',
  postcode: 'Poštanski broj',
  city: 'Grad',
  country: 'Zemlja',
  subscribe: 'Pretplatite se',
  recurrent_fee_info: 'Naplaćuje se svaki',
  one_time_fee_info: 'Jednokratna naknada.',
  new_account_title: 'Izraditi novi račun',
  payment_data_title: 'Pojedinosti o plaćanju',
  billing_data_checkbox_question: 'Podaci o naplati',
  confirmation_modal_header: 'Radnja zahtijeva potvrdu',
  confirmation_modal_warning: 'Jeste li sigurni da želite nastaviti?',
  no_way_to_undo_action: 'Ova se radnja ne može poništiti!',
  delete_payment_method: 'Ukloni karticu',
  add_payment_method: 'Dodaj karticu',
  fill_card_details: 'Ispunite podatke o plaćanju.',
  subscriptions: 'Pretplate',
  subscription: 'Pretplata',
  invoice_number: 'Dostavnica',
  number: 'Broj',
  status: 'Status',
  details: 'pojedinosti',
  created_at: 'Stvoreno',
  subscription_status_initiated: 'Neplaćeno',
  subscription_status_active: 'Aktivan',
  subscription_status_inactive: 'Neaktivan',
  subscription_status_canceled: 'Otkazano',
  ends_at: 'Završava u',
  ends_at_default: 'Vrijedi do kraja obračunskog razdoblja',
  select_subscription_to_pay: 'Odaberite pretplatu za plaćanje.',
  pay_with_subscription: 'Platite uz pretplatu',
  bookings_paid_by_subscription_success_message: 'Plaćeno',
  bookings_not_paid_by_subscription_error_message: 'Rezervaciju nije bilo moguće platiti odabranom pretplatom.',
  stripe: 'Pruga',
  provider_tag: 'metoda',
  card: 'Kartica',
  cash: 'Unovčiti',
  transfer: 'Prijenos',
  promo_code_label: 'Kod za popust',
  promo_code_invalid_message: 'Navedeni kod za popust je nevažeći.',
  payment_method_setup_error: 'Došlo je do problema s vašom karticom. Vaša banka je odbila autorizirati karticu za kasnije transakcije. Molimo kontaktirajte svoju banku.',
  active_subscription_missing: 'Za rezervaciju ove usluge potrebna je aktivna pretplata. Nažalost, nemate nikakvu pretplatu za korištenje.',
  area_code: 'pozivni broj',
  usage: 'Korištenje',
  create_account_in_stripe_failed: 'Operater za plaćanje odbio je vaše podatke o pretplati. Molimo provjerite ispravnost obrasca (PIB, broj telefona, e-mail adresa).',
  additional_auth_required: 'Vaša banka zahtijeva dodatnu autorizaciju',
  general_payment_error: 'Došlo je do problema s vašim plaćanjem. Možda vaša kartica ne podržava pretplate, ima ograničenja kupnje ili nema dovoljno sredstava za plaćanje pretplate. Obratite se svojoj banci ili odaberite drugi način plaćanja.',
  subscription_set_successfully: 'Pretplata ispravno postavljena.',
  booking_created: 'Nova rezervacija',
  booking_updated: 'Promjena rezervacije',
  booking_deleted: 'Brisanje rezervacije',
  booking_reminder: 'Podsjetnik za rezervaciju',
  email_notifications: 'Obavijesti e-poštom',
  sms_notifications: 'SMS obavijesti',
  save_user_preferences: 'Spremi postavke',
  free_spots: 'Ostavljena mjesta',
  no: 'Ne',
  yes: 'Da',
  p24_inactive: 'Operater plaćanja Przelewy24 ne može se koristiti. Obratite se svom operateru plaćanja.',
  stripe_customer_invalid: 'Nevažeći Stripe korisnički ID. Način plaćanja ne može se vezati uz kupca.',
  stripe_resource_invalid: 'Odabrani proizvod nije dostupan. Dostupnost proizvoda provjerite kod operatera za plaćanje.',
  account_blocked_message: 'Vaš račun je neaktivan. Molimo kontaktirajte administratora.',
  time_zone: 'Vremenska zona',
  no_spots_left: 'Dosegli ste maksimalan broj dostupnih mjesta.',
  employee_auth_required: 'Prijavite se na svoj račun ili koristite drugu adresu e-pošte. Računi zaposlenika zahtijevaju prijavu.',
  subpage_auth_required: 'Prijavite se na svoj račun kako biste vidjeli karticu.',
  gross: 'Bruto iznos.',
  subscription_login_info: 'Već imate račun? Prijavite se ispod kako biste ispunili svoje podatke.',
  change_date_time: 'Ponovo rasporedite',
  promo_code_checkbox: 'Imam kod za popust',
  booking_promo_code_label: 'Unesite kod za popust',
  select_hour: 'Odaberite vrijeme',
  id: 'Iskaznica',
  booking_rescheduled: 'Vaša rezervacija je promijenjena.',
  booking_rescheduled_fail: 'Ova se rezervacija ne može promijeniti.',
  max_user_bookings_error: 'Premašili ste maksimalan broj rezervacija. Odaberite drugu uslugu ili nas kontaktirajte.',
  in_total: 'Ukupno',
  company: 'Društvo',
  individual: 'Privatna osoba',
  bulk_payment: 'Skupno plaćanje',
  make_bulk_payment: 'Skupno plaćanje',
  simple_store_product_confirmation_title: 'Ispunite obrazac za kupnju',
  select_service_type: 'Odaberite opciju usluge',
  add_to_calendar_button_label: 'Dodaj u Kalendar',
  login_to_use_service_with_subscription: 'Prijavite se na svoj račun kako biste rezervirali termin za ovu uslugu.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Odabrani datum nije dostupan. Molimo odaberite drugi slobodan datum. U slučaju rezervacije više datuma, pobrinite se da se datumi međusobno ne isključuju.',
  select_location_calendar_warning: 'Odaberite lokaciju za učitavanje dostupnih termina.',
  selectable_location_online_title: 'Online',
  change: 'Promijeni',
  show_less_hours_button: 'Prikaži manje sati',
  show_more_hours_button: 'Prikaži više sati',
  add_another_button_title: 'Dodaj još jedan',
  close: 'Zatvori',
  selected_time_slots: 'Odabrani vremenski termini',
  select_location: 'Odaberite lokaciju'
}
